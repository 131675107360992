import { sanitizeHTML } from "@/lib/utils";
import Link from "@/components/ui/Link";

interface AboutDrDeSilvaSectionData {
  heading?: {
    title?: string;
  };
  text?: {
    text?: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
}

export const fields = [
  "heading.title",
  "text.text",
  "button.label",
  "button.href",
] as const;

export default function AboutDrDeSilvaSection({
  data,
}: {
  data: AboutDrDeSilvaSectionData;
}) {
  return (
    <section
      id="AboutDrDeSilvaSection"
      className="w-full bg-black py-14 md:py-[4%]"
    >
      <div className="mx-auto w-[90%] max-w-[820px]">
        <div className="flex flex-col items-center text-center">
          <h2 className="mb-7 text-center font-freightBig text-[30px] leading-[1.1em] text-white md:text-[35px]">
            {data?.heading?.title}
          </h2>
          <div
            className="mb-[26px] text-center font-plusJakarta font-bold text-[18px] leading-[1.5em] text-white md:text-[17px] [&>ul]:list-disc [&>ul]:mx-auto [&>ul]:text-white [&>ul]:pl-5 [&>ul]:text-left"
            dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
          />
          {data?.button && (
            <Link
              href={data?.button?.href || ""}
              className="mt-6 inline-flex items-center justify-center rounded-full border border-[#b2b2b2] bg-transparent px-[35px] py-[15px] font-plusJakarta text-[12px] font-bold uppercase tracking-[0.1em] text-white transition-all duration-300 hover:border-black hover:bg-[#645e5e]"
            >
              {data?.button?.label}
            </Link>
          )}
        </div>
      </div>
    </section>
  );
}
