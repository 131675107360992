import Image from "next/image";
import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface AboutSectionData {
  button?: {
    href?: string;
    label?: string;
  };
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  image?: {
    alt?: string;
    image_file?: {
      image?: {
        id?: string;
        filename_disk?: string;
      };
    };
  };
  video?: {
    embed_url: string;
    embed_title: string;
    photo?: {
      id?: string;
      filename_disk?: string;
    };
  };
}

interface AboutSectionProps {
  data?: AboutSectionData;
}

export const fields = [
  "button.href",
  "button.label",

  "heading.text",
  "heading.title",

  "subheading.text",
  "subheading.title",

  "text.text",

  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",

  "video.embed_url",
  "video.embed_title",
  "video.photo.id",
  "video.photo.filename_disk",
] as const;

export default function AboutSection({ data }: AboutSectionProps) {

  return (
    <section id="AboutSection" className="py-16 md:py-20 text-base">
      <div className="container">
        {data?.subheading && (
          <h4 className="font-aileron font-bold text-xs uppercase tracking-wider text-secondary-dark mb-4 max-w-[1410px] mx-auto">
            {data.subheading.title || data.subheading.text}
          </h4>
        )}
        <div className="flex flex-col lg:flex-row lg:space-x-8 max-w-[1410px] mx-auto justify-center">
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
            {data?.heading && (
              <h2 className="font-freightBig text-4xl leading-tight md:leading-normal md:text-5xl text-secondary-dark md:mb-6">
                {data.heading.title || data.heading.text}
              </h2>
            )}
            {data?.video && (
              <div className="relative w-full">
                {data.video.photo?.id ? (
									<div className="relative w-full rounded-[18px] overflow-hidden mt-6">
                  <a href={data.video.embed_url} target="_blank" rel="noopener noreferrer" className="relative block">
                    <Image
                      src={getReadableImageUrl(
                        data.video.photo.id || "",
                        data.video.photo.filename_disk || ""
                      )}
                      alt={data.video.embed_title || "Video thumbnail"}
                      width={980}
                      height={653}
                      quality={95}
                      loading={"lazy"}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <svg width="68" height="48" viewBox="0 0 68 48" className="pointer-events-none">
                        <path fill="#FF0000" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"></path>
                        <path fill="#FFFFFF" d="M 45,24 27,14 27,34"></path>
                      </svg>
                    </div>
                  </a>
									</div>
                ) : (
                  <div className="relative w-full aspect-w-16 aspect-h-9 rounded-[18px] overflow-hidden mt-6">
                    <iframe
                      src={data.video.embed_url}
                      title={data.video.embed_title}
                      loading={"lazy"}
                      className="w-full h-full"
                      allowFullScreen
                    />
                  </div>
                )}
								</div>
            )}
            {data?.image && (
              <div className="relative w-full max-w-[600px] rounded-[18px] overflow-hidden mt-6">
                <Image
                  src={getReadableImageUrl(
                    data.image.image_file?.image?.id || "",
                    data.image.image_file?.image?.filename_download || ""
                  )}
                  alt={data.image.alt || ""}
                  width={980}
                  height={653}
                  quality={95}
                  loading={"lazy"}
                />
              </div>
            )}
          </div>
          <div className="w-full lg:w-1/2">
            {data?.text && (
              <div
                className="prose prose-base max-w-none leading-6 font-aileron [&>ul>li::marker]:text-black text-black"
                dangerouslySetInnerHTML={sanitizeHTML(data.text.text || "")}
              />
            )}
            {data?.button && (
              <div className="mt-12 text-center">
                <Button asChild>
                  <Link href={data.button.href || ""}>{data.button.label}</Link>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
