import Link from "@/components/ui/Link";
import Button from "../ui/button";
import { sanitizeHTML } from "@/lib/utils";

interface AreYouReadyData {
  heading?: {
    title?: string;
  };
  text?: {
    text?: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
}

export const fields = [
  "heading.title",
  "text.text", 
  "button.label",
  "button.href"
] as const;

export default function AreYouReady({ data }: { data?: AreYouReadyData }) {
  return (
		<section id='AreYouReady' className='bg-[#dddddd] py-10'>
			<div className='max-w-[1080px] mx-auto px-4'>
				<div className='block md:flex items-center justify-between gap-8'>
					<div className='mb-4 md:mb-0 text-center md:text-left'>
						<h2
							className="font-freightBig text-4xl leading-tight md:leading-normal md:text-5xl text-secondary-dark mb-8 text-left"
							dangerouslySetInnerHTML={sanitizeHTML(
								data?.heading?.title ||
									'Are You Suitable Candidate for A Facelift?'
							)}
						/>
						<div
							className='prose text-sm font-light font-aileron'
							dangerouslySetInnerHTML={sanitizeHTML(
								data?.text?.text ||
									'Answer 30 KEY questions (take less than 5 mins) and receive free customised results and advice.'
							)}
						/>
					</div>
					{data?.button?.href && (
						<div className='flex justify-center text-center'>
							<Button asChild>
								<Link href={data.button.href}>
									{data.button.label || 'Take the Online Assessment'}
								</Link>
							</Button>
						</div>
					)}
				</div>
			</div>
		</section>
	)
}
