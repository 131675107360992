import Link from "@/components/ui/Link";
import Button from "../ui/button";

interface AboutNavigationSectionData {
  buttons: {
    menu_items_id: {
      href: string;
      label: string;
    };
  }[];
}

interface AboutNavigationSectionProps {
  data: AboutNavigationSectionData;
}

export const fields = [
  "buttons.menu_items_id.href",
  "buttons.menu_items_id.label",
] as const;

export default function AboutNavigationSection({
  data,
}: AboutNavigationSectionProps) {
  return (
    <section id="about-navigation" className="py-20 md:py-24">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-6 flex flex-col items-center">
            {data.buttons
              .slice(0, Math.ceil(data.buttons.length / 2))
              .map((item) => (
                <div key={item.menu_items_id.href} className="block">
                  <Button asChild variant="outline">
                    <Link href={item.menu_items_id.href}>
                      {item.menu_items_id.label}
                    </Link>
                  </Button>
                </div>
              ))}
          </div>
          <div className="space-y-6 flex flex-col items-center">
            {data.buttons
              .slice(Math.ceil(data.buttons.length / 2))
              .map((item) => (
                <div key={item.menu_items_id.href} className="block">
                  <Button asChild variant="outline">
                    <Link href={item.menu_items_id.href}>
                      {item.menu_items_id.label}
                    </Link>
                  </Button>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}
