import Image from "next/image";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface AboutTheAuthorWhiteProps {
  data: AboutTheAuthorWhiteData;
}

export interface AboutTheAuthorWhiteData {
  image: {
    alt: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
  heading: {
    text: string;
  };
  text: {
    text: string;
  };
}

export const fields = [
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",
  
  "heading.text",

  "text.text",
] as const;

export default function AboutTheAuthorWhite({ data }: AboutTheAuthorWhiteProps) {
  const imageUrl = getReadableImageUrl(data?.image?.image_file?.image?.id || '', data?.image?.image_file?.image?.filename_download || '');

  return (
    <section id="about-the-author-white">
      <div className="container py-14">
        <div className="flex flex-col md:flex-row md:gap-8 max-w-[1080px] mx-auto">
          <div className="w-full md:w-1/4">
            <div className="relative">
              <Image
                src={imageUrl}
                alt={data?.image?.alt || ''}
                width={900}
                height={749}
                className="rounded-[22px] w-full"
                quality={95}
                loading={"lazy"}
              />
            </div>
          </div>
          <div className="w-full md:w-3/4 mt-8 md:mt-0">
            {data?.heading?.text && <h2 className="font-freightBig text-2xl md:text-3xl lg:text-4xl mb-6 leading-normal" 
            dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.text || '') } />}

            {data?.text?.text && <div className="text-base space-y-6">
              <div
                className="[&>ul>li]:list-disc [&>ul>li]:pl-4"
                dangerouslySetInnerHTML={sanitizeHTML(data.text?.text)}
              />
            </div>}
          </div>
        </div>
      </div>
    </section>
  );
}
