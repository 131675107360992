import Image from "next/image";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface AboutTheAuthorProps {
  data?: any;
}

export interface AboutTheAuthorData {
  photo: {
    image_file: {
      image: string;
    };
    alt: string;
  };
  heading: {
    text: string;
  };
  text: {
    text: string;
  };
}

export const fields = [
  "id",
  "photo.image_file.image.id",
  "photo.image_file.image.filename_download",
  "photo.alt",

  "heading.text",
  "text.text",
] as const;

export default function AboutTheAuthor({ data }: AboutTheAuthorProps) {


  return (
    <section id="about-the-author" className="bg-black">
      <div className="container py-14">
        <div className="flex flex-col md:flex-row md:gap-8 max-w-[1080px] mx-auto">
          <div className="w-full md:w-1/4">
            <div className="relative">
              <Image
                src="/images/dr-julian-de-silva.jpg"
                alt="Dr Julian De Silva"
                width={900}
                height={749}
                className="rounded-[22px] w-full"
                quality={95}
                loading={"lazy"}
              />
            </div>
          </div>
          <div className="w-full md:w-3/4 mt-8 md:mt-0">
            <h2 className="font-freightBig text-2xl md:text-3xl lg:text-4xl text-white mb-6 leading-normal">
              About Dr Julian De Silva
            </h2>
            <div className="text-white text-base space-y-6">
              <p>
                Dr Julian De Silva is a London-based facial cosmetic surgeon specializing in natural-looking results. He is known for his expertise in facial plastic surgery and his artistic approach to facial aesthetics.
              </p>
              <p>
                With over 15 years of experience, Dr De Silva has developed innovative techniques in facial surgery, particularly in deep plane face and neck lifts, rhinoplasty, and eyelid surgery. He is one of the few surgeons in the UK specializing exclusively in facial cosmetic surgery.
              </p>
              <p>
                Dr De Silva completed his surgical training in London and furthered his expertise through fellowships in facial cosmetic surgery in Los Angeles and New York. He is a member of the Royal College of Surgeons and holds multiple international certifications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
