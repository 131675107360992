import Image from "next/image";
import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface AboutSectionRigthVideoData {
  button?: {
    href?: string;
    label?: string;
  };
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  image?: {
    alt?: string;
    image_file?: {
      image?: {
        id?: string;
        filename_disk?: string;
      };
    };
  };
  video?: {
    embed_url: string;
    embed_title: string;
  };
}

interface AboutSectionRigthVideoProps {
  data?: AboutSectionRigthVideoData;
}

export const fields = [
  "button.href",
  "button.label",

  "heading.text",
  "heading.title",

  "subheading.text",
  "subheading.title",

  "text.text",

  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",

  "video.embed_url",
  "video.embed_title",
] as const;

export default function AboutSectionRigthVideo({ data }: AboutSectionRigthVideoProps) {
  return (
		<section id='AboutSectionRigthVideo' className='pt-16 md:pt-20 text-base'>
			<div className='container'>
				{data?.heading && (
					<h2 className='font-freightBig text-4xl leading-tight md:leading-normal md:text-5xl text-secondary-dark mb-8 text-left'>
						{data.heading.title || data.heading.text}
					</h2>
				)}
				<div className='flex flex-col lg:flex-row lg:space-x-8 mx-auto justify-center md:gap-16'>
					<div className='order-2 lg:order-1 w-full lg:w-1/2 mb-8 lg:mb-0'>
						{data?.text && (
							<div
								className='text-base font-light font-aileron prose max-w-none leading-6 [&>ul>li::marker]:text-black text-black'
								dangerouslySetInnerHTML={sanitizeHTML(data.text.text || '')}
							/>
						)}
					</div>
					<div className='order-1 lg:order-2 w-full lg:w-1/2 mb-8 lg:mb-0'>
						{data?.subheading && (
							<h4 className='font-aileron font-bold text-xs uppercase tracking-wider text-secondary-dark mb-4'>
								{data.subheading.title || data.subheading.text}
							</h4>
						)}
						{data?.video && (
							<div className='relative w-full aspect-w-16 aspect-h-9 rounded-[18px] overflow-hidden'>
								<iframe
									src={data.video.embed_url}
									title={data.video.embed_title}
									loading={'lazy'}
									className='w-full h-full'
									allowFullScreen
								/>
							</div>
						)}
						{data?.image && (
							<div className='relative w-full max-w-[600px] rounded-[18px] overflow-hidden'>
								<Image
									src={getReadableImageUrl(
										data.image.image_file?.image?.id || '',
										data.image.image_file?.image?.filename_download || ''
									)}
									alt={data.image.alt || ''}
									width={980}
									height={653}
									quality={95}
									loading={'lazy'}
								/>
							</div>
						)}
					</div>
				</div>
				{data?.button && (
					<div className='mt-4 text-left'>
						<Button asChild>
							<Link href={data.button.href || ''}>{data.button.label}</Link>
						</Button>
					</div>
				)}
			</div>
		</section>
	)
}
